
/*
"signup": {
        "title" : "Inscription",
        "action": "Je souhaite m'inscrire",
        "vetOnly": "L'inscription est strictement réservée aux vétérinaires",
        "price1" : "Il n'y a aucun coût d'inscription, ni mensuel. Vous pourrez effectuer un certain nombre d'actions gratuitement. Par la suite, vous pourrez acheter des actions à la pièce ou un forfait mensuel.",
        "feature" : "Découvrez les possibilités :",
        "warning" : "Prenez-note que votre compte sera vérifié avant d'être activé. Vous devez fournir un numéro de permis valide",
        "create": "Créer mon compte",
        "personalSection": "Informations du compte",
        "personalSectionWarning" : "Les informations personnelles ne seront pas partagées ni visibles pour vos clients.",
        "cieSection": "Informations de la compagnie/enregistrement"
    },
*/

import React, { useState } from 'react'
import { Box, Button, FormLabel, Paper, Typography } from '@mui/material'
import logo from '../../img/logo-nb.png'
import { useTranslation } from 'react-i18next';
import ConfigCieHeader from '../managment/cie/section/ConfigCieHeader';
import {emptyConfig, MainPreferenceSection} from '../managment/cie/section/ConfigPreference';
import {emptyProfile, MainProfileSection} from '../managment/profile/BaseProfile';
import { emptyHeader } from '../headers/BaseHeader';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import Signup from '../../api/Signup';
import { useDispatch } from 'react-redux';
import { logger } from '../common/Logger';
import { messageStore } from '../common/InfoMessage';
import { useNavigate } from 'react-router-dom';
import { signupCompleteUrl } from '../Navigator';

const EMPTY_CLIENT = { "header": emptyHeader, "account": emptyProfile, "config": emptyConfig }

const SignupUI = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [t, i18n] = useTranslation()

  const dispatch = useDispatch();

  const [client, setClient] = useState(EMPTY_CLIENT);

  const handleInputChange = (section, fieldName, value) => {

    if (client[section][fieldName] !== value) {
      const curSection = {...client[section],
        [fieldName]: value
       }
      setClient({...client,
        [section]: curSection
      })
    }
  };



const createAccount = (event) => {
  event.preventDefault();
  
  const rawPhone = client.account.phone?.replace('(', '')?.replace(')', '')?.replace(' ', '')?.replace('-', '');

  const signupData = {
    "header": {...client.header,
      preference: client.config
    }, 
    "account": {...client.account,
      phone: rawPhone
    }
   }

  dispatch(addLoadingCount())
  new Signup().startRegistration(signupData)
    .then((data)=>{
        logger.log("fini: ", data)
        messageStore.sendMessage(t('saveSuccess'), "success")
        navigate(signupCompleteUrl)
      },(err)=>{

        messageStore.sendMessage(err.message, "error")
        logger.error(err);
      })
    .catch(err=>{
      logger.error(err)
      messageStore.sendMessage(err, "error")
    })
    .finally(()=>dispatch(subLoadingCount()))
  }

  
  return (
    
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
        <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }} >
              {t('signup.title')}
        </Box>
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', width: '100%' }} component={'span'}>
        <form id="clientForm"  onSubmit={createAccount}>
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
          <div className="login">
            <div className='form'>
              
            <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
            <Box textAlign='left' >
              <Typography color="inherit" variant="h5" component="h1" align='center'>
                {t('signup.personalSection')}
              </Typography>
              <FormLabel >{t('signup.personalSectionWarning')}</FormLabel>
              <Box sx={{ maxWidth: 500}}>
                <MainProfileSection item={client.account} handleInputChange={(fieldName, value) => handleInputChange("account", fieldName, value)} isEditMode={true} />
              </Box>
              <Typography color="inherit" variant="h5" component="h1" align='center'>
                {t('signup.cieSection')}
              </Typography>
              <FormLabel >{t('signup.cieSectionWarning')}</FormLabel>
              <Box sx={{ maxWidth: 500}}>
                <ConfigCieHeader item={client.header} handleInputChange={(fieldName, value) => handleInputChange("header", fieldName, value)} isEditMode={true}  />
              </Box>
              <Box sx={{ maxWidth: 500}}>
                <MainPreferenceSection
                  item={client.config} 
                  handleConfigInputChange={(fieldName, value) => handleInputChange("config", fieldName, value)}
                  isEditMode={true} /> 
              </Box>
             

              <div className='formfield'>
                <Button data-testid="btnLogin" type='submit' variant='contained'>{t('signup.create')}</Button>
              </div>
          
              </Box>
              </Paper>
            </div>
            </div>
        </Box>
      </form>
      </Paper>
    </Box>
  )
}

export default SignupUI
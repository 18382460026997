import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Checkbox, Typography, InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CalculIcon } from '../common/IconFactory';
import { useTranslation } from 'react-i18next';

const ProfitCalculator = ({ prixCourant, inclureTaxes, multiplicateur }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation()
  const [currentPrixCourant, setCurrentPrixCourant] = useState(prixCourant || 0);
  const [currentInclureTaxes, setCurrentInclureTaxes] = useState(inclureTaxes || false);
  const [currentMultiplicateur, setCurrentMultiplicateur] = useState(multiplicateur || 1.5);
  const [resultat, setResultat] = useState(0);

  const handlePrixCourantChange = (event) => {
    const newValue = parseFloat(event.target.value) || 0;
    setCurrentPrixCourant(newValue);
  };

  const handleInclureTaxesChange = (event) => {
    setCurrentInclureTaxes(event.target.checked);
  };

  const handleMultiplicateurChange = (event) => {
    const newValue = parseFloat(event.target.value) || 1.5;
    setCurrentMultiplicateur(newValue);
  };

  // useEffect to recalculate resultat on state changes
  useEffect(() => {
    calculateResultat();
  }, [currentPrixCourant, currentInclureTaxes, currentMultiplicateur]);

  const calculateResultat = () => {
    const taxe = currentInclureTaxes ? 0.15 : 0;
    const total = currentPrixCourant * (1 + taxe) * currentMultiplicateur;
    setResultat(total.toFixed(2));
  };

  return (
    <Box>
      <Typography variant="h6">Calculette de profit</Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TextField
          label={t('calculette.cost')}
          type="number"
          value={currentPrixCourant}
          onChange={handlePrixCourantChange}
          sx={{ width: 200 }}
        />
    
        <InputLabel htmlFor="chkTax" sx={{ mx: 2 }}>{t('calculette.tax')} (15%)
          <Checkbox
            id="chkTax"
            checked={currentInclureTaxes}
            onChange={handleInclureTaxesChange}            
            sx={{ mt: 1 }}
          />
        </InputLabel>
        <TextField
          label={t('calculette.multiplier')}
          type="number"
          value={currentMultiplicateur}
          onChange={handleMultiplicateurChange}
          sx={{ width: 200, mt: 1 }}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">{t('calculette.price')} : ${resultat}</Typography>
      </Box>
    </Box>
  );
};


export const ProfitCalculatorDialog = ({ prixCourant, inclureTaxes, multiplicateur }) => {
  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation()
  const [open, setOpen] = useState(false);
  
  const curPrixCourant = prixCourant ? prixCourant : 1
  const curInclureTaxes = inclureTaxes ? inclureTaxes : false
  const curMultiplicateur = multiplicateur ? multiplicateur : 1.5

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CalculIcon
        title={t('calculette.title')}
        onClick={handleOpen}/>
      <Dialog open={open} onClose={handleClose}>
        
        <DialogContent>
          <ProfitCalculator 
            prixCourant={curPrixCourant}
            multiplicateur={curMultiplicateur}
            inclureTaxes={curInclureTaxes} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('button.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default ProfitCalculator;

import React, { useEffect }  from 'react';
import ClientHeader from "../layout/ClientHeader"
import TabSelector from "../layout/TabSelector"
import { ViewBill } from "./ViewBill"
import { CreateBill } from "./CreateBill"
import { useState } from "react"
import { GetRequiredView } from "../Navigator"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../services/authenticate';
import { LoadActionsLimit } from '../headers/ActionsLimitLoader';
import Clients from '../../api/Clients';
import { setClient } from '../common/ClientSlice';
import { useDispatch } from 'react-redux';

const BillManager = () => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))
  
  useEffect(() => {
    ReactGA.event("page_view", {"page_location": "bills", "client_id": GetClientKey(), "page_title": "bills_" + currentView});
  }, [currentView]);


  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }

  const saveCallback = (clientKey, item) => {
    new Clients().Get(clientKey)
    .then(setClient)
    .then(dispatch)
    .then(() => {
    setItemToEdit(null)
    if (item) {
      setItemNoToView(item.no)
    }
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
    })
  }
  return (
      <>
      <ClientHeader 
        allowSelection={currentView !== "edit"} 
        showAnimals={currentView !== "view"}
        allowAnimalClearable={true}/>
      <TabSelector 
        onViewChange={setCurrentView}
        pageTitle={t('billing.title')}
        availableView={availViews}
        currentView={currentView}
        />
         <LoadActionsLimit />
        { currentView === "view" && <ViewBill itemNo={itemNoToView} handleEdit={handleEdit} saveCallback={saveCallback}/> }
        { currentView === "edit" && <CreateBill itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
        { currentView === "create" && <CreateBill saveCallback={saveCallback} /> }
      </>
    );
}

export default BillManager
import Typography from '@mui/material/Typography';
import React from 'react'
import DayPicker from "../date/DatePicker";
import { convertToSystemDateFormat } from '../date/DateUtils';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import {sexeMap, typeMap} from './BaseAnimal'
import AnimalAction from './AnimalAction'
import WeightInput, { WeightTextDisplay } from '../input/WeightInput';
import { GetWeightType } from '../common/CommonSlice';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StandardTextField } from '../input/TextFieldStyle';
import { animalsLogo } from './AnimalLogo';
import KeyVal from '../input/KeyVal';
import { useSelector } from 'react-redux';


function AnimalSection({animalData, isEditMode, handleInputChange, isPrintMode}) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const pref = useSelector(state => state.common.header)?.payload?.preference

  if (isEditMode) {
    return getEditSection(animalData, handleInputChange, pref, t)
  }

  return getViewSection(animalData, isPrintMode, t)
}



function getViewSection(animalData, isPrintMode, t) {
  const lastConsult = animalData.consultations?.length > 0 ? animalData.consultations[animalData.consultations.length - 1] : null


  return (<Box>
    <Typography key="iconTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="right">
      
      {
        !isPrintMode && <AnimalAction animalData={animalData} />
      }
      
    </Typography>

    <Stack spacing={2}>
    {!animalData.isActif && 
      <Box sx={{display: 'inline-flex'}}>
          <Typography color="red" align="left" sx={{  mx: 2 }}>
            Innactif
          </Typography>
        </Box>
      }
    <KeyVal icon={animalsLogo[animalData.type]} iconTooltip={typeMap[animalData.type]} value={animalData.name} />
      

    
    <KeyVal label={t('animal.item.sex')} value={sexeMap[animalData.sexe]} />
    <KeyVal label={t('animal.item.chip')} value={animalData.micropuce} />
    <KeyVal label={t('animal.item.race')} value={animalData.race} />
    <KeyVal label={t('animal.item.color')} value={animalData.color} />
    
   
    <WeightTextDisplay weight={animalData.weight} weightType={animalData.weightType} />

    <WeightTextDisplay weight={lastConsult?.consultation?.weight} weightType={lastConsult?.consultation?.weightType} title={t("animal.item.lastWeight")}/>

    

   
    <KeyVal label={t('animal.item.birth')} value={animalData.birthdate ? animalData.birthdate : "Inconnue"} />

    </Stack>

  </Box>)
}


function getEditSection(animalData, handleInputChange, preference, t) {

  return (<>
  <Box>
        <Typography key="headerTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
          <StandardTextField label={t('animal.item.nameLong')} sx={{ my: 2, mx: 2 }}
            required
            
            fullWidth
            minLength={2}
            maxLength={128}
            testid="txtName"
            onChange={(e) => handleInputChange('name', e.target.value)}
            value={animalData.name} variant="standard"/>

          <InputLabel  htmlFor="animalActif" sx={{ mx: 2 }}>Innactif
            <Checkbox label={t('animal.item.innactive')}  sx={{ mx: 2 }}
              id="animalActif" inputProps={{ 'data-testid': 'chkActif' }}
              checked={!animalData.isActif && animalData.isActif !== undefined} 
              onChange={(e) => handleInputChange('isActif', !e.target.checked)}
              />
          </InputLabel> 
          
          
          </Typography>
          <Typography key="regularTypo" sx={{mx: 2 }} color="text.secondary" align="left">
          
            <FormLabel id="type" sx={{  my: 2, mx: 2 }} >{t('animal.item.species')}
              <RadioGroup sx={{ mx: 2 }} id={"an_type_" + animalData.no}
                row
                aria-labelledby="type"
                
                name="radio-buttons-group"
                value={animalData.type}
                onChange={(e) => handleInputChange('type', e.target.value)}
              >
                {Object.keys(typeMap).map((key, i) => (
                  <FormControlLabel key={i} required  value={key} control={<Radio inputProps={{ 'aria-label': "opt" + key }}/>} label={typeMap[key]} />
                ))}
              </RadioGroup>
            </FormLabel>



            <FormLabel id="sexe" sx={{  my: 2, mx: 2 }} >{t('animal.item.sex')}
              <RadioGroup sx={{mx: 2 }} id={"an_sexe_" + animalData.no}
                row
                aria-labelledby="sexe"
                
                name="radio-buttons-group"
                value={animalData.sexe}
                onChange={(e) => handleInputChange('sexe', e.target.value)}
              >
                {Object.keys(sexeMap).map((key, i) => (
                  <FormControlLabel key={i} required  value={key} control={<Radio inputProps={{ 'aria-label': "opt" + key }} />} label={sexeMap[key]} />
                ))}

              </RadioGroup>
            </FormLabel>

            <StandardTextField label={t('animal.item.chip')} sx={{ my: 2, mx: 2 }}
              
              maxLength={60}
              testid="txtChip"
              onChange={(e) => handleInputChange('micropuce', e.target.value)}
              value={animalData.micropuce} variant="standard"/>

            <StandardTextField id="race" label={t('animal.item.race')} sx={{ my: 2, mx: 2 }}
            required
            
            maxLength={60}
            testid="txtRace"
            onChange={(e) => handleInputChange('race', e.target.value)}
            value={animalData.race} variant="standard"/>
        
            <StandardTextField label={t('animal.item.color')} 
              required
              
              maxLength={60}
              testid="txtColor"
              onChange={(e) => handleInputChange('color', e.target.value)}
              value={animalData.color} variant="standard"/>
          
            <WeightInput 
              weight={animalData.weight}
              weightType={animalData.weightType}
              isEditMode={true} 
              defaultWeightType={GetWeightType(preference, "other")}
              onWeightChange={(newValue) => handleInputChange('weight', newValue)}
              onWeightTypeChange={(newValue) => handleInputChange('weightType', newValue)}
              />

          </Typography> 
          <Typography key="dateTypo" sx={{ my: 2, mx: 4 }} color="text.secondary" align="left">
              <FormLabel id="birth" sx={{  my: 2, mx: 2 }} >{t('animal.item.birth')} : </FormLabel>
                  <DayPicker 
                    label={t('dateSelectionLong')}
                    inputChange={(e) => handleInputChange('birthdate', convertToSystemDateFormat(e))}
                    required={true}
                    inputProps={{'aria-label': "txtBirthdate"}}
                    value={animalData.birthdate}
                    maxDate={new Date()}/>

                <StandardTextField  label={t('animal.item.birthInMonth')} sx={{ mx: 2, my: -1 }}
                  
                  min={0} max={999}  maxLength={3}
                  testid="txtMonthAge"
                  onChange={(e) => handleInputChange('monthAge', e.target.value)}
                  value={animalData.monthAge} type="number" variant="standard"/>
          </Typography>
        </Box>
        </>)

}
export {AnimalSection}
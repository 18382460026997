import React  from 'react';
import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';


function ClientCard({clientData, isPrintMode}) {
    // eslint-disable-next-line
    const [t, i18n] = useTranslation()
    return <>
            { clientData &&  
            v2(clientData, isPrintMode, t)
            }
            </>
}
        

function v2(clientData, isPrintMode, t) {
    return (
    <Grid container spacing={0} columns={10} 
        className='header-grid-container' 
        sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
        <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1'}}>
        <Typography sx={{ my: 1 }} variant="h6">
        {t('client.infoClient')}
        </Typography>
        </Grid>

        <Grid item xs={4}>{t('client.item.name')} :</Grid>
        <Grid item xs={5} >{clientData.name}</Grid>

        <Grid item xs={4} >{t('client.item.addr')} :</Grid>
        <Grid item xs={6} >{isPrintMode && clientData.addr1} 
            {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr1}</a>}
        </Grid>
        
        <Grid item xs={4} ></Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.addr2} 
            {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr2}</a>}
        </Grid>


        <Grid item xs={4} >{t('client.item.phone')} :</Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.phone} 
            {!isPrintMode && <a href={"tel:" + clientData.phone}>{clientData.phone}</a>}
        </Grid>

        <Grid item xs={4} >{t('client.item.email')} :</Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.email} 
            {!isPrintMode && <a href={"mailto:" + clientData.email}>{clientData.email}</a>}
        </Grid>
    </Grid>
    )
}
export default ClientCard

import React  from 'react';
import { useState } from "react"

import {ViewEventConfirmation} from './ViewEventConfirmation'
import TabSelector from "../layout/TabSelector"

const EventsConfirmManager = () => {
    const [currentView, setCurrentView] = useState("view")
    // eslint-disable-next-line no-unused-vars
    const [availViews, setAvailViews] = useState([{key: 0, title: "Visualisation", name: "view"}])
    
    return (
        <>
            <TabSelector 
                onViewChange={setCurrentView}
                pageTitle="Constultation confirmée"
                availableView={availViews}
                currentView={currentView}
                />
        <ViewEventConfirmation />
       </>
      );
}


export default EventsConfirmManager


import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FlatHeaderRenderer from '../header/FlatHeaderRenderer';
import { useSelector } from 'react-redux';
import {ViewButtonSection} from '../view/ViewButtonSection';
import { handleDownload } from '../GeneratePDF';
import EmptyView from '../view/EmptyView';
import EventsConfirmTable from './EventsConfirmTable';
import EventConfirmSelector from './EventConfirmSelector';
import { LoadHeader } from '../headers/HeaderLoader';


export function ViewEventConfirmation() {
    const pdfContentRef = React.createRef();
    const [isPrintMode, setIsPrintMode] = useState(false);

    const header = useSelector(state => state.common.header)
    const [eventConfirm, setEventConfirm] = useState(null);

    const getPdfName = () => {
      return eventConfirm.key + "-" + eventConfirm.client.name + "-" + eventConfirm.client.animal.name + ".pdf"
    }
    
    const getView =(header, eventConfirm, isPrintMode) => {
      return (
          <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}  component={'span'}>
              <LoadHeader />
              <EventConfirmSelector eventConfirm={eventConfirm} setEventConfirm={setEventConfirm} />

              <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
                  <FlatHeaderRenderer 
                      isPrintMode={isPrintMode}
                      headerData={header} 
                      clientData={eventConfirm?.client} 
                      animalData={eventConfirm?.client?.animal}
                  />
                  <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h5">
                      Détails de l&#39;évènement
                  </Typography>

                  <table ref={pdfContentRef} border="1" width="100%">
                    <tbody>
                      <EventsConfirmTable items={eventConfirm} isPrintMode={isPrintMode} />
                    </tbody>
                  </table>

              </div>
              <ViewButtonSection 
                  isEditMode={false}
                  selectedItem={eventConfirm}
                  handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
              />
          </Paper>
      );
      }

    if (eventConfirm === undefined || eventConfirm === null) {
      return (
        <EmptyView title='Veillez sélectionner un événement'>
          <EventConfirmSelector eventConfirmKey={eventConfirm?.key} setEventConfirm={setEventConfirm} />
        </EmptyView>
      )
    }

    return getView(header?.payload, eventConfirm, isPrintMode)
    
}


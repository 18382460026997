
/*
"signup": {
        "title" : "Inscription",
        "action": "Je souhaite m'inscrire",
        "vetOnly": "L'inscription est strictement réservée aux vétérinaires",
        "price1" : "Il n'y a aucun coût d'inscription, ni mensuel. Vous pourrez effectuer un certain nombre d'actions gratuitement. Par la suite, vous pourrez acheter des actions à la pièce ou un forfait mensuel.",
        "feature" : "Découvrez les possibilités :",
        "warning" : "Prenez-note que votre compte sera vérifié avant d'être activé. Vous devez fournir un numéro de permis valide",
        "create": "Créer mon compte",
        "personalSection": "Informations du compte",
        "personalSectionWarning" : "Les informations personnelles ne seront pas partagées ni visibles pour vos clients.",
        "cieSection": "Informations de la compagnie/enregistrement"
    },
*/

import React from 'react'
import { Box, Paper } from '@mui/material'
import logo from '../../img/logo-nb.png'
import { useTranslation } from 'react-i18next';
import {emptyConfig} from '../managment/cie/section/ConfigPreference';
import {emptyProfile} from '../managment/profile/BaseProfile';
import { emptyHeader } from '../headers/BaseHeader';

 // eslint-disable-next-line
const EMPTY_CLIENT = { "header": emptyHeader, "account": emptyProfile, "config": emptyConfig }

const SignupCompleteUI = () => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation()


  return (
    
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
        <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }} >
              {t('signupComplete.title')}
        </Box>
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', width: '100%' }} component={'span'}>

          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>

              
            <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
              <Box textAlign='left' >
              {t('signupComplete.description')}
  
                </Box>
              </Paper>
  
        </Box>
    
      </Paper>
    </Box>
  )
}


export default SignupCompleteUI
import React, { useEffect }  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const RxSelector = ({animal, rx, setRx, disable}) => {

  useEffect(() => {
    if ((rx === undefined || rx === null) && animal?.rxs?.length > 0) {
      setRx(animal.rxs[animal.rxs.length - 1])
    }
  }, [animal]);

  
  const intenalOnChange = (event, newValue) => {
    ReactGA.event("select_item", {"item_list_name": "rx", "items": [{"item_id" : newValue.no, "item_name" : selectOptionTitre(newValue), "item_category": animal.no }] });
    setRx(newValue)
  }
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  return (<Autocomplete 
    aria-label="lstRxs"
    disabled={disable}
    disablePortal
    disableClearable
    onChange={intenalOnChange}
    sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
    value={rx === undefined ? null : rx}
    options={animal?.rxs ? animal.rxs.toReversed() : []}
    getOptionLabel={selectOptionTitre}
    renderInput={(params) => <TextField {...params} label={t('rx.selectorTitle')}/>}
  />)
}

const selectOptionTitre = (option) => {
  const itemResume = option.items.map(item => item.name).join(', ');

  return option.date + " (" + itemResume + ")"
}


RxSelector.propTypes = {
  animal: PropTypes.object,
  rx: PropTypes.object,
  setRx: PropTypes.func.isRequired,
  disable: PropTypes.bool
};


export default RxSelector
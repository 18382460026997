
import React, { useEffect }  from 'react';
import { useState } from "react"
import TabSelector from "../../layout/TabSelector"
import { CieHeaderView } from './CieHeaderView';
import { useTranslation } from 'react-i18next';
import { CiePreferenceView } from './CiePreferenceView';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import { PlanView } from './PlanView';
import { PlanBuy } from './PlanBuy';



const CieManager = () => {
    // eslint-disable-next-line
    const [t, i18n] = useTranslation()
    const [currentView, setCurrentView] = useState("cie")
    // eslint-disable-next-line
    const [availViews, setAvailViews] = useState([{key: 0, title: t('header.description'), name: "cie"},
    {key: 1, title: t('header.config.title'), name: "pref"},
    {key: 1, title: t('header.plan.title'), name: "plan"},
    {key: 1, title: t('header.plan.buy'), name: "buy"}
    ])

    useEffect(() => {
        ReactGA.event("page_view", {"page_location": "header", "client_id": GetClientKey(), "page_title": "header_" + currentView});
    }, [currentView]);

      
    return (
        <>
            <TabSelector 
            onViewChange={setCurrentView}
            pageTitle={t('header.title')}
            availableView={availViews}
            currentView={currentView}
            />
            { currentView === "cie" && <CieHeaderView /> }
            { currentView === "pref" && <CiePreferenceView />}
            { currentView === "plan" && <PlanView/> }
            { currentView === "buy" && <PlanBuy/> }
        </>
        );
}


export default CieManager


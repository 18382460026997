import React, { useEffect }  from 'react';
import {CreateConsultation} from './CreateConsultation'
import {ViewConsultation} from './ViewConsultation'
import { useState } from "react"
import ClientHeader from "../../layout/ClientHeader"
import TabSelector from "../../layout/TabSelector"
import { GetRequiredView } from '../../Navigator'
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import { LoadActionsLimit } from '../../headers/ActionsLimitLoader';


const ConsultationManager = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))

  useEffect(() => {
    ReactGA.event("page_view", {"page_location": "consultations", "client_id": GetClientKey(), "page_title": "consultations_" + currentView});
  }, [currentView]);


  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }
  const saveCallback = (item) => {
    setItemToEdit(null)
    setItemNoToView(item.no)
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
  }
  return (
    <>
    <ClientHeader allowSelection={currentView !== "edit"}/>
    <TabSelector 
      onViewChange={setCurrentView}
      pageTitle="Consultation"
      availableView={availViews}
      currentView={currentView}
      />
       <LoadActionsLimit />
      { currentView === "view" && <ViewConsultation itemNo={itemNoToView} handleEdit={handleEdit}/> }
      { currentView === "edit" && <CreateConsultation itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
      { currentView === "create" && <CreateConsultation saveCallback={saveCallback}/> }
    </>
  );
}


export default ConsultationManager

import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import EmptyView from '../../view/EmptyView';
import { addRow, deleteRow, handleInputChange } from '../../view/TableEditionHandler';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import VaccinTable from './VaccinTable';
import { emptyItem, emptyVaccin } from './BaseVaccin';
import DayPicker from '../../date/DatePicker';
import VaccineSelector from './VaccineSelector';
import { useTranslation } from 'react-i18next';
import { addItemAnimal, updateItemAnimal } from '../common/ItemUtils';
import { LoadHeader } from '../../headers/HeaderLoader';
import { canCreateVaccine, handleAction } from '../../../services/AccessHub';
import NoMoreActionView from '../../view/NoMoreActionView';
import { logger } from '../../common/Logger';
import { v4 as uuid } from 'uuid';

export function CreateVaccine({itemToEdit, saveCallback}) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [vaccine, setVaccine] = useState(isUpdate ? itemToEdit : {...emptyVaccin, items: [emptyItem]});
  const limits = useSelector(state => state.common.limits)?.payload

  React.useEffect(() => {
    handleSetNo();
  }, [vaccine, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && vaccine.no === "") {
      logger.debug("Need new no")
      handleInputChange(vaccine, setVaccine, null, "no", uuid())
    }
  }

  logger.trace("curVaccine : ", vaccine)
  const internalHandleSave = async (event) => {
      event.preventDefault();

      if (isUpdate) {
        return updateItemAnimal(curAnimal.payload.key, "vaccines", vaccine, saveCallback, dispatch, t, "vaccine")
      } else {
        handleAction("vaccine", dispatch)
        return addItemAnimal(curAnimal.payload.key, "vaccines", vaccine, saveCallback, dispatch, t, "vaccine")
      }
  }


  const getView =(header, curClient, curAnimal, vaccine) => {
    return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <LoadHeader />
      { isUpdate &&
        <VaccineSelector animal={curAnimal} vaccine={vaccine} disable={true}/>
      }
              
      <form id="vaccineForm"  onSubmit={internalHandleSave}>
        <FlatHeaderRenderer 
            headerData={header} 
            clientData={curClient} 
            animalData={curAnimal}
            extraData={{
              "date": <DayPicker 
                value={ vaccine.date}
                inputChange={ (e) => handleInputChange(vaccine, setVaccine, null, "date", convertToSystemDateFormat(e))}
                /> }}/>
  
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align='center' variant="h5">
            Certificat de vaccination
          </Typography>  
          <VaccinTable 
            items={vaccine.items} 
            handleInputChange={(index, fieldName, value) => handleInputChange(vaccine, setVaccine, index, fieldName, value)} 
            deleteItem={(index) => deleteRow(vaccine, setVaccine, index)}
            isEditMode={true}
            isPrintMode={false}/>  
      <br/>
      <CreateButtonSection addRowHandler={() => addRow(vaccine, setVaccine, emptyItem)} />
  </form>
  </Paper>)
  }

  if (!canCreateVaccine(limits)) {
    return (<NoMoreActionView/>)
  }

  if ((vaccine === undefined || vaccine === null) || (curAnimal === undefined || curAnimal === null)) {
    return (<EmptyView title={t('animal.noSelection')} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, vaccine)
  


}


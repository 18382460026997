import React  from 'react';
import {ViewEvents} from './ViewEvents';
import TabSelector from "../layout/TabSelector";
import { useState } from "react";
import { CreateEvents } from './CreateEvents';
import ClientHeader from '../layout/ClientHeader';

const EventsManager = () => {
  const [currentView, setCurrentView] = useState("view")
  // eslint-disable-next-line
  const [availViews, setAvailViews] = useState([{key: 0, title: "Consultation à confirmer", name: "view"},
    {key: 1, title: "Consultation ad-hoc", name: "create"}])
    

  const saveCallback = () => {
    //setItemToEdit(null)
    // setItemNoToView(item.no)
    setCurrentView("view")
  }

  return (
      <>
        { currentView === "create" && <ClientHeader/> }
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle="Gestion des demandes de consultation"
          availableView={availViews}
          currentView={currentView}
          />
        { currentView === "view" && <ViewEvents /> }
        { currentView === "create" && <CreateEvents saveCallback={saveCallback}/> }
      </>
    );
}


export default EventsManager
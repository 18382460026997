import React, { useEffect }  from 'react';
import { ViewVaccine } from "./ViewVaccine"
import { CreateVaccine } from "./CreateVaccine"
import { useState } from "react"
import ClientHeader from "../../layout/ClientHeader"
import TabSelector from "../../layout/TabSelector"
import { GetRequiredView } from "../../Navigator"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import { LoadActionsLimit } from '../../headers/ActionsLimitLoader';

const VaccineManager = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))
  
  useEffect(() => {
    ReactGA.event("page_view", {"page_location": "vaccines", "client_id": GetClientKey(), "page_title": "vaccines_" + currentView});
  }, [currentView]);


  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }
  
  const saveCallback = (item) => {
    setItemToEdit(null)
    setItemNoToView(item.no)
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
  }

  return (
    <>
      <ClientHeader allowSelection={currentView !== "edit"}/>
      <TabSelector 
        onViewChange={setCurrentView}
        pageTitle="Vaccin"
        availableView={availViews}
        currentView={currentView}
        />
         <LoadActionsLimit />
      { currentView === "view" && <ViewVaccine itemNo={itemNoToView} handleEdit={handleEdit}/> }
      { currentView === "edit" && <CreateVaccine itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
      { currentView === "create" && <CreateVaccine saveCallback={saveCallback}/> }
    </>
  );
}


export default VaccineManager

import React, { useEffect }  from 'react';
import { useState } from "react"
import TabSelector from "../../layout/TabSelector"
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../../services/authenticate';
import ProfileSection from './ProfileSection';



const ProfileManager = () => {
    // eslint-disable-next-line
    const [t, i18n] = useTranslation()
    const [currentView, setCurrentView] = useState("profile")
    // eslint-disable-next-line
    const [availViews, setAvailViews] = useState([{key: 0, title: t('account.title'), name: "profile"}
    ])

    useEffect(() => {
        ReactGA.event("page_view", {"page_location": "profile", "client_id": GetClientKey(), "page_title": "profile_" + currentView});
    }, [currentView]);

      
    return (
        <>
            <TabSelector 
            onViewChange={setCurrentView}
            pageTitle={t('account.title')}
            availableView={availViews}
            currentView={currentView}
            />
            { currentView === "profile" && <ProfileSection /> }
        </>
        );
}


export default ProfileManager


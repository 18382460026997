import Typography from '@mui/material/Typography';
import * as React from 'react'
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import ClientAction from './ClientAction'
import { StandardTextField } from '../input/TextFieldStyle';
import EmailTextField from '../input/EmailTextField';
import PhoneTextField from '../input/PhoneTextField';
const emptyClient = {"isActif": true,  
  "no": undefined, 
  "name": "", 
  "phone": "", 
  "addr1": "",
  "addr2": "",
  "email": ""}


function GetClientSection({clientData, isEditMode, handleInputChange, isPrintMode}) {

    return (<>
            <Typography key="iconTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="right">          
            {
              !isEditMode && !isPrintMode && <ClientAction clientData={clientData} />
            }
          </Typography>
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" >
            <StandardTextField label="Nom du client"
              required
              minLength={5}
              maxLength={60}
              disabled={!isEditMode}
              fullWidth
              testid="txtName"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={clientData.name} variant="standard"/>
          
            <InputLabel 
              required disabled={!isEditMode}
              htmlFor="tel">Numéro de téléphone</InputLabel>
            
            {!isEditMode && 
            <Link aria-label="lnkPhone"  id="tel" href={"tel:" + clientData.phone}>{clientData.phone}</Link>}

            {isEditMode && 
              <PhoneTextField
                required
                inputProps={{ 'data-testid': 'txtPhone' }}
                value={clientData.phone}
                disabled={!isEditMode}
                name="textmask"
                onChange={(e) => handleInputChange('phone', e.target.value)}
                id="tel" variant="standard"
              />
            }
              
            <StandardTextField label="Ligne d'adresse 1"
              required
              disabled={!isEditMode}
              fullWidth
              maxLength={128}
              testid="txtAddr1"
              onChange={(e) => handleInputChange('addr1', e.target.value)}
              value={clientData.addr1} variant="standard"/>

            <StandardTextField label="Ligne d'adresse 2"
              disabled={!isEditMode}
              fullWidth
              maxLength={128}
              testid="txtAddr2"
              onChange={(e) => handleInputChange('addr2', e.target.value)}
              value={clientData.addr2} variant="standard"/>
       
            {!isEditMode &&
            <>
              <InputLabel
                required 
                disabled={!isEditMode} 
                htmlFor="email">Courriel</InputLabel>

              <Link aria-label="lnkEmail"  id="email" href={"mailto:" + clientData.email}>{clientData.email}</Link>
            </>}
            {isEditMode &&  <EmailTextField id="email" label="Courriel"
                required
                disabled={!isEditMode}
                fullWidth
                
                testid="txtEmail"
                onChange={(e) => handleInputChange('email', e.target.value)}
                value={clientData.email} variant="standard"/>
            }
          </Typography>
            </>)

}
export {GetClientSection, emptyClient}

import React, { useEffect }  from 'react';
import { ViewAnimal } from "./ViewAnimal"
import { CreateAnimal } from "./CreateAnimal"
import { useState } from "react"
import ClientHeader from "../layout/ClientHeader"
import TabSelector from "../layout/TabSelector"
import Animals from "../../api/Animals"
import { setAnimal } from "../common/ClientSlice"
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../services/authenticate';

const AnimalManager = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [itemToEdit, setItemToEdit] = useState(null)
  const [currentView, setCurrentView] = useState("view")
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))

  useEffect(() => {
    ReactGA.event("page_view", {"page_location": "animals", "client_id": GetClientKey(), "page_title": "animals_" + currentView});
  }, [currentView]);

  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }
  const saveCallback = (item) => {
    new Animals().Get(item.key).then(setAnimal).then(dispatch)
    setItemToEdit(null)
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
  }
  return (
      <>
        <ClientHeader 
          allowSelection={currentView !== "edit"} 
          showAnimals={currentView !== "create"}/>
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle={t('animal.description')}
          availableView={availViews}
          currentView={currentView}
          />
        { currentView === "view" && <ViewAnimal handleEdit={handleEdit}/> }
        { currentView === "edit" && <CreateAnimal itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
        { currentView === "create" && <CreateAnimal saveCallback={saveCallback}/> }
      </>
    );
}

export default AnimalManager
import React, { useEffect }  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const VaccineSelector = ({animal, vaccine, setVaccine, disable}) => {

  useEffect(() => {
    if ((vaccine === undefined || vaccine === null) && animal?.vaccines?.length > 0) {
      setVaccine(animal.vaccines[animal.vaccines.length - 1])
    }
  }, [animal]);

  const intenalOnChange = (event, newValue) => {
    ReactGA.event("select_item", {"item_list_name": "vaccine", "items": [{"item_id" : newValue.no, "item_name" : selectOptionTitre(newValue), "item_category": animal.no }] });
    setVaccine(newValue)
  }

  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  return (<Autocomplete
    aria-label="lstVaccines"
    disabled={disable}
    disablePortal
    disableClearable
    onChange={intenalOnChange}
    sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
    value={vaccine === undefined ? null : vaccine}
    options={animal?.vaccines ? animal.vaccines.toReversed() : []}
    getOptionLabel={selectOptionTitre}
    renderInput={(params) => <TextField {...params} label={t('vaccine.selectorTitle')}/>}
  />)
}

const selectOptionTitre = (option) => {
  return option.date
}

VaccineSelector.propTypes = {
  animal: PropTypes.object,
  vaccine: PropTypes.object,
  setVaccine: PropTypes.func.isRequired,
  disable: PropTypes.bool
};
  

export default VaccineSelector
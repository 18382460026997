import React, { useEffect } from 'react'
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

const ConsultationSelector = ({animal, consultation, setConsultation, disable}) => {

  useEffect(() => {
    if ((consultation === undefined || consultation === null) && animal?.consultations?.length > 0) {
      setConsultation(animal.consultations[animal.consultations.length - 1])
    }
  }, [animal]);


  const intenalOnChange = (event, newValue) => {
    ReactGA.event("select_item", {"item_list_name": "consultation", "items": [{"item_id" : newValue.no, "item_name" : selectOptionTitre(newValue), "item_category": animal.no }] });
    setConsultation(newValue)
  }
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  return (<Autocomplete
    aria-label="lstConsultaions"
    disabled={disable}
    disablePortal
    disableClearable
    onChange={intenalOnChange}
    sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
    value={consultation === undefined ? null : consultation}
    options={animal?.consultations ? animal.consultations.toReversed() : []}
    getOptionLabel={selectOptionTitre}
    renderInput={(params) => <TextField {...params} label={t('consultation.selectorTitle')}/>}
  />)
}

const selectOptionTitre = (option) => {
  return option.date + " - " + option.consultation.consultWhy
}

ConsultationSelector.propTypes = {
  animal: PropTypes.object,
  consultation: PropTypes.object,
  setConsultation: PropTypes.func.isRequired,
  disable: PropTypes.bool
};


export default ConsultationSelector
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { formatToDisplayFormatFromString } from '../../date/DateUtils'
import { useSelector } from 'react-redux';
import {ViewButtonAnimalPartSection} from '../../view/ViewButtonSection';
import { handleDownload } from '../../GeneratePDF';
import EmptyView from '../../view/EmptyView';
import VaccineSelector from './VaccineSelector';
import Signature from '../../input/Signature';
import VaccinTable from './VaccinTable';
import { useTranslation } from 'react-i18next';
import { LoadHeader } from '../../headers/HeaderLoader';

export function ViewVaccine({itemNo, handleEdit}) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)
  const [vaccin, setVaccin] = useState(itemNo ? curAnimal?.payload?.vaccines?.find(obj => obj.no === itemNo) : null);

  if (vaccin && (curAnimal?.payload?.vaccines === undefined ||  curAnimal?.payload?.vaccines.findIndex(obj => obj.no === vaccin.no) === -1)) {
    setVaccin(null)
    return (<GetEmptyView animal={curAnimal} setItem={setVaccin} />)
  }


  const getPdfName = () => {
    return vaccin.no + "-" + curClient.payload.name + "-" + curAnimal.payload.name + ".pdf"
  }
    
    const getView =(header, client, animal, vaccin, isPrintMode) => {
      return (
          <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
            <LoadHeader />
            <Typography color="text.secondary" align='center'>
              <VaccineSelector animal={animal} vaccine={vaccin} setVaccine={setVaccin}/>
            </Typography>
            <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
            <FlatHeaderRenderer 
                isPrintMode={isPrintMode}
                headerData={header} 
                clientData={client} 
                animalData={animal}
                extraData={{
                    "date": formatToDisplayFormatFromString(vaccin.date)}}  />
            
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align='center' variant="h5">
              {t('vaccine.mainTitle')}
            </Typography>

            <Typography sx={{ my: 3, mx: 2 }} color="text.secondary" align="left"  >
                <>
                {t('vaccine.attestation', { name: animal.name, date: formatToDisplayFormatFromString(vaccin.date) })}
                <p/>
                {t('vaccine.protectionAttestation', { name: animal.name})}
                </>
            </Typography>
            <VaccinTable  
                items={vaccin.items} 
                isEditMode={false}
                isPrintMode={isPrintMode}/>
            <br/>
            <Signature isPrintMode={isPrintMode} date={vaccin.date} displayLicense={true} />
            </div>

            <ViewButtonAnimalPartSection 
                isEditMode={true}
                selectedItem={vaccin}
                handleEdit={() => handleEdit(vaccin)}
                allowDelete={true}
                animal={animal}
                itemArrayName="vaccines"
                handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
            />

              
          </Paper>
      );
      }

  if (vaccin === undefined || vaccin === null) {
    return (<GetEmptyView animal={curAnimal} setItem={setVaccin} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, vaccin, isPrintMode)
    
}

const GetEmptyView = ({animal, setItem}) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  return (
    <EmptyView title={t('vaccine.noSelection')}>
      <VaccineSelector animal={animal?.payload} setVaccine={setItem}/>
    </EmptyView>
  )
}


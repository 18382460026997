import { React, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeleteIconContent } from '../common/IconFactory';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { logger } from "./Logger";

export function DeleteItem({itemName, handleDelete}) {
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleShowDialog = () => {
        setDialogOpen(true)
    };

    const handleDialogConfirm = () => {
        setDialogOpen(false)
        
        logger.debug("Confirmation de suppression de ", itemName)
        dispatch(addLoadingCount())
        handleDelete()
          .finally(()=>dispatch(subLoadingCount()))
    };
    
    const handleDialogCancel = () => {
    setDialogOpen(false)
    };


    return (
        <>
        <Button variant="contained" onClick={handleShowDialog} aria-label='btnDelete' ><DeleteIconContent/>{t('button.delete')}</Button>
          <Dialog
            open={dialogOpen}
            onClose={handleDialogCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {t('button.confirmDelete')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              {t('button.askConfirmDelete')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button aria-label='btnCancellDelete' onClick={handleDialogCancel}>{t('button.cancel')}</Button>
              <Button aria-label='btnConfirmDelete' onClick={handleDialogConfirm} autoFocus>
                {t('button.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
}
import React  from 'react';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DayPicker from '../../date/DatePicker';
import DayHelpPicker from '../../date/DayHelpPicker';
import dayjs from 'dayjs';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { DeleteIcon } from '../../common/IconFactory';
import { useTranslation } from 'react-i18next';
import { StandardTextField } from '../../input/TextFieldStyle';


function VaccinTable({items, handleInputChange, deleteItem, isEditMode}) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const rows = isEditMode ? GetEditRow(items, handleInputChange, deleteItem) : getViewRow(items)

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={isEditMode ? "38%" : "50%"}><b>{t('vaccine.item.vaccine')}</b></TableCell>
              <TableCell align="center"><b>{t('vaccine.item.effectif')}</b></TableCell>
              <TableCell width={isEditMode ? "38%" : ""} align="center"><b>{t('vaccine.item.nextDate')}</b></TableCell>
              {isEditMode && <TableCell width="44px" align="right"></TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


function GetEditRow(items, handleInputChange, deleteItem) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="left" component="th" scope="row" padding='checkbox'>
            <StandardTextField label={t('vaccine.item.vaccineLong')} size='small'
              required
              fullWidth
              maxLength={60}
              testid="txtName"
              onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              value={row.name} variant="standard"/>
            
          </TableCell>
          <TableCell align="center" padding='none'>
            <DayPicker
              inputProps={{'aria-label': "txtEffective"}}
              label={t('dateSelectionLong')}
              value={dayjs(row.effective)}
              maxDate={dayjs(row.rappel)}
              inputChange={(e) => handleInputChange(index, 'effective', convertToSystemDateFormat(e))}
              />
          </TableCell>
          <TableCell align="center" padding='none'>
            <DayHelpPicker 
              label={t('dateSelectionLong')}
              referenceValue={row.effective}
              value={dayjs(row.rappel)}
              minDate={dayjs(row.effective)}
              inputChange={(e) => handleInputChange(index, 'rappel', convertToSystemDateFormat(e))}
              />
          </TableCell>
          <TableCell align="center" padding='none'><DeleteIcon  key={'btnDelete'+index} onClick={() => deleteItem(index)}/></TableCell>

        </TableRow>
      ))
    )
}

function getViewRow(items) {
    return (
        items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" size='small'>
                {row.name}
            </TableCell>
            <TableCell align="center" size='small'>
                {row.effective}
            </TableCell>
            <TableCell align="center" size='small'>{row.rappel}</TableCell>
        </TableRow>)
        ))
}

export default VaccinTable
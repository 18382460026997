import React  from 'react';
import { Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LogoSection } from '../common/Logo';

function ExtraHeader({extraData, extraChildren, isPrintMode, displayLogo}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation()

  return <>
      { extraData && 
        v2(extraData, isPrintMode, displayLogo, extraChildren, t)    
      }
    </>
}

ExtraHeader.propTypes = {
  extraData: PropTypes.object,
  extraChildren: PropTypes.node,
  isPrintMode: PropTypes.bool,
  displayLogo: PropTypes.bool
};


function v2(extraData, isPrintMode, displayLogo, extraChildren, t) {
  return (
  <Grid container spacing={0} columns={10} 
    className=' header-grid-container' 
    sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
   {displayLogo && 
      <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1' }} className='header-grid-item' >
        <LogoSection />
      </Grid>
    }
    <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1' }} className='header-grid-item' >
      <Typography sx={{ my: 1 }} variant="h6">
        {extraData.title}
      </Typography>
    </Grid>

    {extraData.noTitle && <>
      <Grid item xs={4} >{extraData.noTitle} :</Grid>
      <Grid item xs={6} >{extraData.no}</Grid>
    </>}

    <Grid item xs={4} >{t('date')} :</Grid>
    <Grid item xs={6} >{extraData.date}</Grid>

    {extraChildren}
  </Grid>
  )

}
export default ExtraHeader
import React from 'react'
import { useTranslation } from "react-i18next";
import { InfoIconContent, TooltipIconButton } from "../common/IconFactory";
import { formatToDisplayFormatFromString } from "../date/DateUtils";

const InfoItemTooltip = ({noName, no, updateDate, creationDate}) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const noTxt = <><b>{noName} : </b> {no}</>
  var updTxt = <></>
  var createTxt = <></>
  if (updateDate) {
    updTxt = <><br/><b>{t('message.updateDate')} : </b> {formatToDisplayFormatFromString(updateDate)}</>
  }
  if (creationDate) {
    createTxt = <><br/><b>{t('message.creationDate')} : </b> {formatToDisplayFormatFromString(creationDate)}</>
  }

  return (<TooltipIconButton
        title={<>{noTxt}{updTxt}{createTxt}</>}>
        <InfoIconContent />
      </TooltipIconButton>)
        
  }
  

export default InfoItemTooltip
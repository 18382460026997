import React  from 'react';
import { Button } from "@mui/material";
import { EditItemIconContent, ExportIconContent } from "../common/IconFactory";
import PropTypes from 'prop-types';
import { DeleteItem } from '../common/DeleteItem';
import { deleteItem } from '../medic/common/ItemUtils';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function ViewButtonSection({isEditMode, selectedItem, handleEdit, handleDownload, allowDelete, handleDelete}) {
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  return (<>
    { (isEditMode === undefined || isEditMode) && <Button variant="contained" data-testid='btnEdit'
      onClick={() => {
        handleEdit(selectedItem);
      }} ><EditItemIconContent/>{t('button.edit')}</Button>
    }
    
    <Button variant="contained" data-testid="exportPdf"
      onClick={() => {

          handleDownload(selectedItem);
      }} ><ExportIconContent/>{t('button.print')}</Button>
      { allowDelete && 
      <DeleteItem handleDelete={() => handleDelete(selectedItem, dispatch, t)}/>}
  </>)

} 


export function ViewButtonAnimalPartSection({isEditMode, selectedItem, animal, handleEdit, handleDownload, allowDelete, itemArrayName}) {
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  
  return (<>
    { (isEditMode === undefined || isEditMode) && <Button variant="contained" data-testid='btnEdit'
      onClick={() => {
        handleEdit(selectedItem);
      }} ><EditItemIconContent/>{t('button.edit')}</Button>
    }
    
    <Button variant="contained" data-testid="exportPdf"
      onClick={() => {

          handleDownload(selectedItem);
      }} ><ExportIconContent />{t('button.print')}</Button>
      { allowDelete && 
      <DeleteItem handleDelete={() => deleteItem(itemArrayName, selectedItem, animal, dispatch, t)}/>}
  </>)

} 

ViewButtonSection.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  handleEdit: PropTypes.func ,
  selectedItem: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
  allowDelete: PropTypes.bool
};

import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {ViewButtonSection} from '../view/ViewButtonSection';
import { handleDownload } from '../GeneratePDF';
import EmptyView from '../view/EmptyView';
import { AnimalSection } from './AnimalSection';
import { useTranslation } from 'react-i18next';
import Animals from '../../api/Animals';
import { messageStore } from '../common/InfoMessage';
import { setAnimal, setRefreshAnimal } from '../common/ClientSlice';
import { hasDeletePermission } from '../../services/AccessHub';


export function ViewAnimal({handleEdit}) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)


  const getPdfName = () => {
    return curClient.no + "-" + curClient.payload.name + "-" + curAnimal.payload.name + ".pdf"
  }


  const getView =(client, animal, isPrintMode) => {
    return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}  component={'span'}>
              <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>

              <AnimalSection 
                animalData={animal}
                isEditMode={false}
                isPrintMode={isPrintMode}
              />
              </div>
            <ViewButtonSection 
                isEditMode={true}
                selectedItem={animal}
                allowDelete={hasDeletePermission()}
                handleDelete={deleteAnimal}
                handleEdit={handleEdit}
                handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
            />
        </Paper>
    );
  }


    

    if (curAnimal === undefined || curAnimal === null || curAnimal.payload === null) {
      return (
        <EmptyView title={t('animal.noSelection')}>
        </EmptyView>
      )
    }

    return getView(curClient?.payload, curAnimal?.payload, isPrintMode)
    
}

const deleteAnimal = (selectedItem, dispatch, t) => {
  return new Animals().delete(selectedItem).then((result) => {
    if (result.status === 200) {
      messageStore.sendMessage(t('message.deleteSuccess'))
      dispatch(setAnimal(null))
      dispatch(setRefreshAnimal(true))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
    }
})
//.then(() => window.location.reload())
.catch((error) => messageStore.sendMessage(error.message, "error", t('message.deleteError')))
}


import React from 'react'
import { Box, Paper, Typography } from '@mui/material';
import PlanCard from './plan/PlanCard';
import { useTranslation } from 'react-i18next';
import { LoadActionsLimit } from '../../headers/ActionsLimitLoader';
import { PossibleBuyActionList } from './plan/PlanCardList';

export function PlanBuy() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation()
  const pdfContentRef = React.createRef();

  const getView =() => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadActionsLimit />

        <div ref={pdfContentRef} border="1">
          <Typography sx={{ my: 3, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            Action
          </Typography>
          <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
            { PossibleBuyActionList.map(item => 
              <PlanCard key={item.title} 
                title={item.title} 
                description={item.description}
                number={item.nb}
                price={item.price}
                pricePeriode={item.pricePeriode}
                logo={item.logo}/>)}
            </Box> 
          </div>
        </Paper>
      )
  }

    return getView()
}


import React  from 'react';
import { Button } from "@mui/material";
import { AddItemIconContent, SaveIconContent } from "../common/IconFactory";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export function CreateButtonSection({addRowHandler}) {
    return (<>
      {addRowHandler && <AddRowButton addRowHandler={addRowHandler}/>}
      &nbsp;  
      <SaveButton/>
    </>)

  } 

  function SaveButton(){
    // eslint-disable-next-line
    const [t, i18n] = useTranslation()
    return (
      <Button variant="contained" data-testid='btnSave' type='submit'>
        <SaveIconContent/>{t('button.save')}</Button>
    )

  } 
  function AddRowButton({addRowHandler}){
    // eslint-disable-next-line
    const [t, i18n] = useTranslation()
    return (
      <Button variant="contained" data-testid='btnAddRow'
        onClick={() => {
            addRowHandler();
        }} ><AddItemIconContent/>{t('button.item.add')}</Button>
      )

  } 


  CreateButtonSection.propTypes = {
    addRowHandler: PropTypes.func
  };
  